export const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME;
export const recordingsFormat = process.env.REACT_APP_RECORDINGS_FORMAT;

// core login domain
export const cognitoClientDomain = process.env.REACT_APP_COGNITO_APP_CLIENT_DOMAIN;
export const cognitoAppClientId = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
export const cognitoAppClientSecret = process.env.REACT_APP_COGNITO_APP_CLIENT_SECRET;

export const apiEndpointControllers = process.env.REACT_APP_CONTROLLERS_ENDPOINT;
export const logRocketConfig = {
  isEnabled: process.env.REACT_APP_LOGROCKET_ENABLED,
  appId: process.env.REACT_APP_LOGROCKET_APP_ID,
};

