import { Layout } from "antd";
import { FC, useCallback, useEffect } from "react";
import { Outlet } from "react-router";
import styled from "styled-components";
import useAppStore from "src/controller/app/store";
import retrieveToken from "src/services/auth/retrieveToken";
import getUserFromIdToken from "src/utils/auth/getUserFromIdToken";
import AppHeader from "src/components/App/components/AppHeader";
import { RecordingsProvider } from "src/contexts/Recordings";

const Container = styled.div`
  background-color: white;
  padding-left: 46px;
  padding-right: 46px;
  min-height: calc(100vh - 64px);
`;

const MainLayout: FC = () => {
  // const { logout } = useLogout();
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");
  const { setLogin, userDetails } = useAppStore();
  const { isAuthorized } = userDetails;
  const readTokenFromCode = useCallback(async () => {
    if (!code) return;

    const response = await retrieveToken(code);

    const { id_token, access_token, refresh_token } = response.data;
    sessionStorage.setItem("id_token", id_token);
    sessionStorage.setItem("access_token", access_token);
    sessionStorage.setItem("refresh_token", refresh_token);
    const userDetails = await getUserFromIdToken(id_token!);
    if (userDetails.isAuthorized) {
      await setLogin(userDetails, userDetails.isAdmin);
    }

    window.location.assign(`${window.location.protocol}//${window.location.host}`);
  }, [code, setLogin]);

  useEffect(() => {
    if (code) {
      readTokenFromCode();
    }
  }, [code, readTokenFromCode]);

  return (
    <Layout>
      <AppHeader />
      <Container>
        {isAuthorized ? (
          <RecordingsProvider>
            <Outlet />
          </RecordingsProvider>
        ) : (
          <Outlet />
        )}
      </Container>
    </Layout>
  );
};

export const NoLayout: FC = () => {
  return (
    <Layout>
      <Container>
        <Outlet />
      </Container>
    </Layout>
  );
};

export default MainLayout;
