import LogRocket from "logrocket";
import { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import useAppStore from "src/controller/app/store";
import useAppInitialization from "src/hooks/useAppInitialization";
import LoggedOut from "src/pages/LoggedOut";
import Login from "src/pages/Login";
import NoMatchPage from "src/pages/NoMatchPage";
import Layout from "src/layouts";
import WelcomePage from "src/pages/WelcomePage";
import routesGenerator from "./utils/routesGenerator";
import SuspenseFallback from "src/common/SuspenceFallback";
import ProtectedRoute from "./components/ProtectedRoute";
import routes from "src/routes";

function App() {
  useAppInitialization();

  const { userDetails, isAdminSession } = useAppStore();
  useEffect(() => {
    if (userDetails.isAuthorized) {
      LogRocket.identify(userDetails.id, {
        name: userDetails.fullName.replaceAll("|", " "),
        email: userDetails.id,
        //Add custom attribute below:
      });
    }
  }, [userDetails]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<WelcomePage />} />
        {routesGenerator(routes, ({ Component, path, isProtected, isAdminRoute }) => {
          return (
            Component && (!isAdminRoute || isAdminSession) && (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    {isProtected ? (
                      <ProtectedRoute redirectPath="/login">
                        <Component />
                      </ProtectedRoute>
                    ) : (
                      <Component />
                    )}
                  </Suspense>
                }
              />
            )
          );
        })}
      </Route>
      <Route path="login" key="/login" element={<Login />} />
      <Route path="logout" key="/logout" element={<LoggedOut />} />
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
}

export default App;
