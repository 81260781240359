import { useCallback } from "react";
import { cognitoClientDomain, cognitoAppClientId } from "src/config/config";
import isIframe from "src/utils/isIframe";

export interface IAppActions {
  doLogin: () => Promise<void>;
}

export const useAppActions = (): IAppActions => {

  const doLogin = useCallback(
    async () => {
      const appDomain = cognitoClientDomain;
      const appClientId = cognitoAppClientId;

      const rUri = `${window.location.protocol}//${window.location.host}`;
      const scope = "openid profile email aws.cognito.signin.user.admin";
      const navigateUrl = `${appDomain}/oauth2/authorize?identity_provider=OKta&response_type=code&client_id=${appClientId}&scope=${scope}&redirect_uri=${rUri}`;
      const encodedUrl = encodeURI(navigateUrl);
      if(isIframe()){
        window.open(encodedUrl);
      } else {
        window.location.assign(encodedUrl);
      }
    },
    [],
  );

  return { doLogin };
};
